<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm ref="searchRef" :fields="this.search.fields" :card="this.search.card" @change="searchChange">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="updateCharts">Buscar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="Consumo Electrico">
            <electrico-tab :busqueda="busqueda"></electrico-tab>
          </b-tab>

          <b-tab title="Temperaturas">
            <temperatura-tab :busqueda="busqueda"></temperatura-tab>
          </b-tab>

          <b-tab title="Presiones">
            <presiones-tab :busqueda="busqueda"></presiones-tab>
          </b-tab>

          <b-tab title="Clima">
            <clima-tab :busqueda="busqueda"></clima-tab>
          </b-tab>


        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>
        
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ElectricoTab from './tabs/ElectricoTab.vue'
import TemperaturaTab from './tabs/TemperaturaTab.vue'
import PresionesTab from './tabs/PresionesTab.vue'
import ClimaTab from './tabs/ClimaTab.vue'


import axios from "@axios";
var qs = require('qs');

import {
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BTabs,
  BTab,

  BOverlay,
  BSpinner


} from "bootstrap-vue";

export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    BSpinner,
    BrunaForm,
    BTabs,
    BTab,
    ElectricoTab,
    TemperaturaTab,
    PresionesTab,
    ClimaTab
  },

  methods: {

    updateCharts() {
      let data = this.search.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      // console.log(data);
      this.busqueda = data;
    },

    findFieldByName(fields, name) {

      return fields.find(field => field.id == name)

    },

    searchChange(field) {
      
      // console.log('FORM => ',this.search.fields)
      // if (field.id == "planta")
      // {
      //   this.fet_name = field.value;

      //   let keys = Object.keys(this.searchResults[field.value]);
      //   keys.sort();

      //   this.fet_name = keys

      // console.log('FORM => ',this.fet_name)


      // }

      /*  if(field.id == "equipo")
       {
 
         console.log(field)
 
         let mdf_field = this.findFieldByName(this.search.fields, 'planta')
         let eqp_field = this.findFieldByName(this.search.fields, 'equipo')
 
         let keys = Object.keys(this.searchResults[mdf_field.value][eqp_field.value]);
         keys.sort();
       }
       
       if (field.id == "meses") {
         let mdf_field = this.findFieldByName(this.search.fields, 'planta')
         let fnd_field = this.findFieldByName(this.search.fields, 'equipo')
         let eqp_field = this.findFieldByName(this.search.fields, 'meses')
 
         console.log(mdf_field.value)
         console.log(fnd_field.value)
         console.log(eqp_field.value)
 
         let keys = Object.keys(this.searchResults[mdf_field.value][fnd_field.value][eqp_field.value]);
         keys.sort();
 
         this.fet_name = keys
         console.log("fet_name: ", this.fet_name)
       } */
    },


    roundValue(value, decimales = 2) {
      let tmp = Math.pow(10, decimales)

      return Math.round(value * tmp) / tmp
    },

    onGridReady(gridApi, gridColumnApi, params) {

      this.gridApi = gridApi

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
    },

    onGetRowsLoaded(gridApi, gridColumnApi, params) {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

    },

  },

  mounted() {

    let options = [
      { value: null, text: "Selecciona una opción" }
    ]
    options.push({ value: 'Maipu', text: "Maipu" })

    let mdf_field = this.findFieldByName(this.search.fields, 'planta')

    mdf_field.options = options;
    mdf_field.value = 'Maipu';

  },

  created() {

  },

  data() {

    let debug = (this.$route.query.debug == '1')

    return {

      showOverlay: false,
      overlayMessage: "Cargando...",
      gridApi: null,

      stats: [],
      busqueda: null,
      fet_name: null,
      searchResults: [],

      search: {
        card: {
          title: "Búsqueda",
          subTitle: "",
          tooltip: "",
          sidebarContent: {
            title: "Histórico de Data",
            body: ''
          }
        },
        fields: [
          {
            label: "Planta",
            type: "select",
            id: 'planta',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },

          /*
          {
            label: "Equipo",
            type: "select",
            id: 'equipo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              //{ value: 'Chiller 202', text: "Chiller 202" },
              { value: 'Chiller 401', text: "Chiller 401" },
              //{ value: 'Chiller 450', text: "Chiller 450" },
              { value: 'Chiller 751', text: "Chiller 751" },
              //{ value: 'UMA', text: "UMA" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },
*/
/*
          {
            label: "Meses",
            type: "select",
            id: 'meses',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              //{ value: '4', text: "Abril" },
              //{ value: '5', text: "Mayo" },
              //{ value: '6', text: "Junio" },
              //{ value: '7', text: "Julio" },
              //{ value: '8', text: "Agosto" },
              //{ value: '9', text: "Septiembre" },
              { value: '10', text: "Octubre" },
              { value: '11', text: "Noviembre" },
              { value: '12', text: "Diciembre" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },
          */
        ]
      },
    };
  },
};
</script>
     
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}

span {
  font-size: 14px;
}

.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        